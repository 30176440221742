:root {
  --red: #ff5252;
  --textColor: black;
  --backgroundColor: white;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  line-height: 1.75;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 760px) {
  body {
    font-size: 15px;
  }
}

h1 {
  font-size: 4em;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0.03em;
}

h2 {
  line-height: 1.4;
  margin-top: 1.5em;
}
.view {
  min-height: 60vh;
  margin-bottom: 3em;
}
.view a {
  color: var(--red);
  text-decoration: none;
}

.view a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  h1 {
    font-size: 3em;
  }
}

@keyframes nav {
  from {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

nav {
  animation: nav 0.2s ease-in-out forwards;
  display: flex;
  justify-content: space-between;
  opacity: 0;
}

nav a {
  font-size: 18px;
  color: inherit;
  font-weight: 900;
  margin: 0 0 0 2em;
  letter-spacing: 0.08em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

nav a:hover {
  transition: color 0.2s ease-in-out;
  color: var(--red);
}

@keyframes navdot {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

nav a:before {
  border-radius: 50%;
  content: "";
  background-color: var(--red);
  left: 0;
  height: 10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-200%, -50%);
  width: 10px;
}

nav a.active:before {
  animation: navdot 0.25s ease-in-out forwards 0.25s;
}

@media screen and (max-width: 475px) {
  nav a {
    font-size: 13px;
  }

  nav a:before {
    height: 8px;
    width: 8px;
  }
}

.app {
  padding: 0em;
  text-align: center;
  overflow-x: hidden;
}

.with--accent {
  color: var(--red);
  font-weight: 400;
}

.content {
  border-radius: 4px;
  background-color: white;
  color: #101010;
  max-width: 40em;
  margin: 0 auto;
  padding: 0.75em;
  text-align: left;
}

.content--inner {
  padding: 3em;
}

@media screen and (max-width: 760px) {
  .content--inner {
    padding: 1em;
  }
}

.content--excerpt {
  font-weight: 700;
  font-style: italic;
  margin: 0;
}

.fake-toolbar-div {
  width: 100%;
  height: 150px;
}
@media screen and (max-width: 760px) {
  .fake-toolbar-div {
    height: 110px;
  }
}
@media screen and (max-width: 600px) {
  .fake-toolbar-div {
    height: 83px;
  }
}
@media screen and (max-width: 475px) {
  .fake-toolbar-div {
    height: 65px
  }
}

.content-block {
  letter-spacing: 0.03em;
  font-size: medium;
  width: 100%;
  min-height: 50vh;
}

.block-0 {
  background: inherit;
  color: inherit;
}

.block-1 {
  background: black;
  color: white;
}

.block-2 {
  background: #00203fff;
  color: #adefd1ff;
}

.block-3 {
  background: white;
  color: black;
}

.scroll-to-arrow {
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  transform-origin: right bottom;
  transform: rotate(-90deg);
  color: #000;
  z-index: 100;
  min-width: 170px;
  text-align: left;
}

.scroll-to-arrow .scroll-to-arrow:focus .scroll-to-arrow:active {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #000;
}

.scroll-to-arrow .scroll-line {
  width: 90px;
  height: 2px;
  display: inline-block;
  background: #000;
  position: relative;
  top: -4px;
  left: -10px;
  transition: 0.3s cubic-bezier(0.65, 0, 0.17, 0.98);
}
.scroll-to-arrow:hover .scroll-line {
  width: 40px;
}
.scroll-to-arrow .scroll-arrow {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: url(./assets/arrow-scroll-2.png) no-repeat center center / 9px
    13px;
}
.home-scroll-to {
  right: 4%;
  top: 475px;
}

@media screen and (max-width: 760px) {
  .home-scroll-to {
    top: 450px;
  }
}

.navbar {
  flex-direction: row;
}

@media screen and (max-width: 760px) {
  .navbar {
    flex-direction: column;
  }
}
